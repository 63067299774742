import React from "react";
import ProfileCard from "./components/ProfileCard";
import Header from "./components/Header";
import ImageCard from "./components/ImageCard";
import Footer from "./components/Footer";

const App = () => {
    const d = new Date()
    const month = d.getMonth() + 1
    const day = d.getDate()
    const xmasStatus = (month === 12 && day < 25)

    return (
        <div className="App">
            <Header xmasStatus={xmasStatus}></Header>
            <ProfileCard></ProfileCard>
            <ImageCard></ImageCard>
            <Footer></Footer>
        </div>
    );
}

export default App;
