import React from "react"

const Header = (data) => {
    const xmasStatus = data.xmasStatus
    return (
        <div className="mt-10 mx-auto w-fit flex items-center">
            {xmasStatus ? <img src="/img/xmas/santa.png" alt="サンタの画像" className="header-img" /> : ""}
            <h1 className="font-bold text-2xl sm:text-4xl hachi-maru-pop">抜けは通報のホームページ</h1>
            {xmasStatus ? <img src="/img/xmas/tonakai.png" alt="トナカイの画像" className="header-img" /> : ""}
        </div>
    )
}

export default Header